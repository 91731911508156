import { offersMapping, retentionOfferMapping } from 'middleware/offerMapping';
import { start, success, fail, apiAction } from 'middleware/fetch';
import { resetLoader } from './loading';
import getFlags from './features';
import { getPlatformTypeByTag } from 'components/ui/CustomPage/widgets/OffersList/util';
import { SUBSCRIPTION_GET_ALL } from './subscription';
import { getUserId } from 'selectors/user';
import { RETENTION_DETAILS_FETCH } from './retention';

export const OFFERS_PUBLIC_FETCH = `offers/PUBLIC_FETCH`;
export const OFFERS_PURCHASE_FETCH = `offers/PURCHASE_FETCH`;
export const OFFERS_RETENTION_FETCH = `offers/RETENTION_FETCH`;

const initialState = {
  public: [],
  purchase: [],
  retention: [],
};

const offersReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case start(OFFERS_PUBLIC_FETCH): {
      return {
        ...state,
        public: [],
      };
    }
    case success(OFFERS_PUBLIC_FETCH): {
      const offers = offersMapping(payload.data);
      action.asyncDispatch({
        type: success(SUBSCRIPTION_GET_ALL),
        payload: { data: { items: offers } },
        apiActionContext: payload,
      });
      return {
        ...state,
        public: offers,
      };
    }
    case fail(OFFERS_PUBLIC_FETCH): {
      return {
        ...state,
        public: [],
      };
    }

    case start(OFFERS_PURCHASE_FETCH): {
      return {
        ...state,
        purchase: [],
      };
    }
    case success(OFFERS_PURCHASE_FETCH): {
      const offers = offersMapping(payload.data);
      action.asyncDispatch({
        type: success(SUBSCRIPTION_GET_ALL),
        payload: { data: { items: offers } },
        apiActionContext: payload,
      });
      return {
        ...state,
        purchase: offers,
      };
    }
    case fail(OFFERS_PURCHASE_FETCH): {
      return {
        ...state,
        purchase: [],
      };
    }

    case start(OFFERS_RETENTION_FETCH): {
      return {
        ...state,
        retention: [],
      };
    }
    case success(OFFERS_RETENTION_FETCH): {
      const offers = retentionOfferMapping(offersMapping(payload?.data));
      action.asyncDispatch({
        type: success(RETENTION_DETAILS_FETCH),
        payload: { data: offers },
        apiActionContext: payload,
      });
      return {
        ...state,
        retention: offers,
      };
    }
    case fail(OFFERS_RETENTION_FETCH): {
      return {
        ...state,
        retention: [],
      };
    }
    default:
      return state;
  }
};

const getPublicOffers = () => {
  return (dispatch) => {
    dispatch(resetLoader([OFFERS_PUBLIC_FETCH]));
    dispatch(
      apiAction(OFFERS_PUBLIC_FETCH, `fe-api-getPublicOffers`, {
        path: `web/groups/public${getOfferBucketSuffixKey()}/offers`,
      }),
    );
  };
};

const getPurchaseOffers = ({ tag, userId } = {}) => {
  return (dispatch, getState) => {
    const appState = getState();
    const { systemConfig } = appState;
    const getFeatureFlags = getFlags({
      systemConfig,
    });
    const ctvConfig = getFeatureFlags(`CTV_CONFIG`) || [];
    const platformType = getPlatformTypeByTag({ tag, ctvConfig }) || '';

    dispatch(resetLoader([OFFERS_PURCHASE_FETCH]));
    dispatch(
      apiAction(OFFERS_PURCHASE_FETCH, `fe-api-getPurchaseOffers`, {
        path: `${platformType}/users/${userId}/groups/purchase${getOfferBucketSuffixKey()}/offers`,
      }),
    );
  };
};

const getRetentionOffers = () => {
  return (dispatch, getState) => {
    const appState = getState();
    const { user } = appState;
    const userId = getUserId(user);

    dispatch(resetLoader([OFFERS_RETENTION_FETCH]));
    dispatch(
      apiAction(OFFERS_RETENTION_FETCH, `fe-api-getPurchaseOffers`, {
        path: `web/users/${userId}/groups/retention${getOfferBucketSuffixKey()}/offers`,
      }),
    );
  };
};

const getIsPreviewOffers = () => {
  return window?.location?.search?.includes('setPreview=true');
};

const PreviewOfferBucketSuffixKey = '-preview';

const getOfferBucketSuffixKey = () => {
  return getIsPreviewOffers() ? PreviewOfferBucketSuffixKey : '';
};

export {
  getPublicOffers,
  getPurchaseOffers,
  getIsPreviewOffers,
  getRetentionOffers,
  getOfferBucketSuffixKey,
};

export default offersReducer;
