import { isFailure, isLoaded, isLoading, isSuccess } from 'ducks/loading';
import { OFFERS_PUBLIC_FETCH, OFFERS_PURCHASE_FETCH } from 'ducks/offers';
import { isLoggedIn } from './user';

export const selectOffers = (state) => {
  const isUserLoggedIn = isLoggedIn(state);
  const offers =
    (isUserLoggedIn ? state?.offers?.purchase : state?.offers?.public) || [];
  const offersActionType = isUserLoggedIn
    ? OFFERS_PURCHASE_FETCH
    : OFFERS_PUBLIC_FETCH;
  const offersStatus = {
    isLoading: isLoading(state.loading, offersActionType),
    isLoaded: isLoaded(state.loading, offersActionType),
    isSuccess: isSuccess(state.loading, offersActionType),
    isFailure: isFailure(state.loading, offersActionType),
  };
  return { offers, offersStatus };
};

export const selectRetentionOffers = (state) => {
  return state?.retention?.offers?.[0] || {};
};
